<template>
    <div class="Field__Component">
        <div>
            <slot>
                <b-button @click="openChatModal" icon="arrow-up" type="is-info" size="is-small">פתח צ'אט</b-button>
            </slot>
        </div>
    </div>
</template>

<script>
import { ModalProgrammatic as Modal } from 'buefy';
import DialogChat from '@/components/DialogChat.vue';

export default {
    name: 'FieldRequestOpenChat',
    props: ['value', 'item'],
    methods: {
        openChatModal() {
            Modal.open({
                component: DialogChat,
                customClass: "entity-modal",
                props: {
                    onSuccess: this.onNewEntitySave,
                    store: this.$store,
                    id: this.item.id,
                },
                canCancel: false,
                trapFocus: true,
            });
        }
    }
};
</script>

<style scoped>
.Field__Component a {
    text-align: center;
    display: block;
}
</style>
