<template>
    <div class="Field__Component">
        <div>
            <slot>{{ getExpoType(value) }}</slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value'],
    methods: {
        getExpoType(type) {
            switch (type) {
                case 0:
                    return 'תרגול';
                case 1:
                    return 'וידאו';
                case 2:
                    return 'סימולציה';
                case 6:
                    return 'דיוני';
                case 7:
                    return 'מהותי';
                case 8:
                    return 'דלתא';
                case 9:
                    return 'אינטרוולים';
                case 10:
                    return 'מיומנות';
                case 11:
                    return 'מקוצרת';

                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped></style>
