const widgets = {
    StudentGroups: {
        type: 'StudentGroupsWidget',
        title: 'קבוצות',
        data: (slot) => ({
            request: () => Promise.resolve(slot.privateName),
            mapResponse: (data) => ({ data }),
        }),
    },
    TrackStudents: {
        type: 'TracksStudentsWidget',
        title: 'מסלולים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.privateName),
            mapResponse: (data) => ({ data }),
        }),
    },
    RequestHistory: {
        type: 'RequestHistoryWidget',
        title: 'היסטוריית טיפול בפנייה',
        data: (slot) => ({
            request: () => Promise.resolve(slot.privateName),
            mapResponse: (data) => ({ data }),
        }),
    },
    RequestAdmins: {
        type: 'RequestAdminsWidget',
        title: 'תיוגי צוות פניות',
        data: (slot) => ({
            request: () => Promise.resolve(slot.privateName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LecturesStudents: {
        type: 'LecturesStudentsWidget',
        title: 'הרצאות',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    StudentCourses: {
        type: 'StudentCoursesWidget',
        title: 'השתתפות בקורסים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    StudentExercises: {
        type: 'StudentExercisesWidget',
        title: 'תרגולים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LessonVideos: {
        type: 'LessonVideosWidget',
        title: 'סרטונים מקושרים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LessonExercises: {
        type: 'LessonExercisesWidget',
        title: 'תרגילים מקושרים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LessonLaws: {
        type: 'LessonLawsWidget',
        title: 'חוקים מקושרים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LessonVerdicts: {
        type: 'LessonVerdictsWidget',
        title: 'מקבצי פסיקה מקושרים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LessonBooks: {
        type: 'LessonBooksWidget',
        title: 'ספרים מקושרים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    GroupLessons: {
        type: 'GroupLessonsWidget',
        title: 'יחידות לימוד',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    GroupStudents: {
        type: 'GroupStudentsWidget',
        title: 'תלמידים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    GroupEscorts: {
        type: 'GroupEscortsWidget',
        title: 'מלווים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    GroupExercises: {
        type: 'GroupExercisesWidget',
        title: 'תרגולים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    ExpoChapterQuestions: {
        type: 'ExpoChapterQuestionsWidget',
        title: 'שאלות',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LectureUsers: {
        type: 'LectureUsersWidget',
        title: 'תלמידים רשומים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LectureLearningTrackSubjects: {
        type: 'LectureLearningTrackSubjectsWidget',
        title: 'נושאים',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
    LectureTrackSubjectLectures: {
        type: 'LectureTrackSubjectLecturesWidget',
        title: 'הרצאות',
        data: (slot) => ({
            request: () => Promise.resolve(slot.lastName),
            mapResponse: (data) => ({ data }),
        }),
    },
};

export default widgets;
