<template>
    <div class="Field__Component">
        <div><slot>
            <router-link :style="{ textDecoration: 'underline' }" :to="`/${field.RouteName || field.Id}/${item.id}`"><b-icon style="color: #337ab7;" icon="cog"></b-icon></router-link>
        </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value', 'field', 'item'],
};
</script>

<style scoped>

</style>
