var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      [
        _vm._t("default", [
          _c(
            "router-link",
            {
              style: { textDecoration: "underline" },
              attrs: {
                to:
                  "/" +
                  (_vm.field.RouteName || _vm.field.Id) +
                  "/" +
                  _vm.item.id
              }
            },
            [
              _c("b-icon", {
                staticStyle: { color: "#337ab7" },
                attrs: { icon: "cog" }
              })
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }