var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c("label", [
      _c("span", [_vm._t("default")], 2),
      _c("input", {
        staticClass: "filter-number-input",
        class: { "vs--has-value": _vm.value },
        attrs: { type: "number", name: "" },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            return _vm.$emit("input", +$event.target.value)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }