<template>
    <div class="Field__Component">
        <label>
            <span><slot></slot></span>
            <input class="filter-number-input" type='number' name=''
            :class="{'vs--has-value': value}"
             @input="$emit('input', +$event.target.value)" :value="value" />
        </label>
    </div>
</template>

<script>

export default {
    name: 'FilterString',
    props: ['value'],

};
</script>
<style scoped>
.filter-number-input {
    background-color: white;
    border-color: #dbdbdb;
    border: 1px solid black;
    color: #363636;
    border-radius: 4px;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    height: 2.5rem;
}
</style>
