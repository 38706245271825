<template>
  <div class="Field__Component">
    <label for="" class="Field__Component__Label">
      <span><slot></slot></span>
      <v-select
        dir="rtl"
        label="Text"
        :options="data"
        :clearable="false"
        :placeholder="'בחר ערך מהרשימה'"
        :reduce="(item) => item.Value"
        @input="setSelected"
        :value="value && value.Value"
      >
        <div slot="no-options">לא נמצאו ערכים דומים</div>
      </v-select>
      <div class="is-flex Field__Component__Label__Date">
        <b-datepicker
          :diabled="true"
          v-model="fromDate"
          @input="setSelected('from')"
          append-to-body
          placeholder="תאריך התחלה"
          icon-prev="chevron-right"
          icon-next="chevron-left"
          icon="calendar-today"
          locale="en-GB"
          :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']"
          :month-names="[
            'ינואר',
            'פברואר',
            'מרץ',
            'אפריל',
            'מאי',
            'יוני',
            'יולי',
            'אוגוסט',
            'ספטמבר',
            'אוקטובר',
            'נובמבר',
            'דצמבר',
          ]"
          close-on-click
          editable
        >
        </b-datepicker>
        <b-datepicker
          :diabled="true"
          v-model="toDate"
          @input="setSelected('to')"
          append-to-body
          placeholder="תאריך סיום"
          icon-prev="chevron-right"
          icon-next="chevron-left"
          icon="calendar-today"
          locale="en-GB"
          :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']"
          :month-names="[
            'ינואר',
            'פברואר',
            'מרץ',
            'אפריל',
            'מאי',
            'יוני',
            'יולי',
            'אוגוסט',
            'ספטמבר',
            'אוקטובר',
            'נובמבר',
            'דצמבר',
          ]"
          close-on-click
          editable
        >
        </b-datepicker>
      </div>
    </label>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "FilterDateTypes",
  props: ["value", "options", "optionsAsync", "clearable"],
  components: {
    vSelect,
  },
  computed: {
    sortedList() {
      if (this.data) {
        const clone = this.data;
        return clone.sort(this.sort);
      }
      return [];
    },
  },
  data() {
    return {
      data: [],
      fromDate: this.value?.fromDate
        ? this.timeToTimepicker(this.value.fromDate)
        : null,
      toDate: this.value?.toDate
        ? this.timeToTimepicker(this.value.toDate)
        : null,
      position: null,
    };
  },
  created() {
    this.setSelected(this.options[0].Value);
    if (this.options[0].preSettedDate) {
      // for default sets
      if (!this.toDate) {
        this.toDate = this.timeToTimepicker(
          this.options[0].preSettedDate.endDate
        );
      }
      this.setSelected(this.options[0].preSettedDate.value);
      this.position = this.options[0].preSettedDate.position;
    }
    if (this.optionsAsync) {
      this.optionsAsync().then((r) => {
        this.data = r;
      });
    } else {
      this.data = this.options.slice(0);
    }
  },
  methods: {
    timeToTimepicker(time) {
      const targetTime = new Date(time);
      const tzDifference = targetTime.getTimezoneOffset();
      return new Date(targetTime.getTime() + tzDifference * 60 * 1000);
    },
    setSelected(value) {
      const obj = { ...this.value };
      if (value === "from") {
        // obj.fromDate = this.fromDate;
        obj.fromDate = moment(this.fromDate).utcOffset(0, true).format();
      } else if (value === "to") {
        // obj.toDate = this.toDate;
        obj.toDate = moment(this.toDate).utcOffset(0, true).format();
      } else {
        obj.Value = value;
      }
      this.$emit("input", obj);
    },
    sort(a, b) {
      return a.Text - b.Text;
    },
  },
};
</script>
<style scoped lang="scss">
.Field__Component__Label__Date {
  > div {
    width: 100%;
    margin: 15px 5px;

    &:first-of-type {
      margin-right: 0;
    }
    &:last-of-type {
      margin-left: 0;
    }
  }
}
</style>
