<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">פנייה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <template v-if="isLoading">
                    <div class="loading">
                        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
                    </div>
                </template>
                <div class="DialogReportPaid__Component__Loading">
                    <p>* התלמיד יכול להגיב רק על פנייה בסטטוס בטיפול שהתגובה האחרונה היא של צוות הקורס</p>
                    <div class="responses">
                        <div :class="response.isAdminResponse ? 'admin-response' : 'user-response'"
                            v-for="(response, idx) in responses" class="response" :key="response.id">
                            <div class="response-seen">
                                <b-icon v-if="response.isViewedByStudent" :title="'נצפה'" pack="fa" icon="eye"></b-icon>
                            </div>
                            <div v-if="isEditModal !== idx" v-html="response.text"></div>
                            <textarea v-else type="text" v-model="response.text" name="" id=""> </textarea>
                            <div class="response-buttons">
                                <div style="cursor: pointer;" v-if="response.isAdminResponse"
                                    @click="deleteMessage(response.id)">
                                    <b-icon :title="'מחק'" class="delete" pack="fa" icon=""></b-icon>
                                </div>
                                <div style="cursor: pointer;" v-if="response.isAdminResponse"
                                    @click="openEditModal(idx)">
                                    <b-icon :title="'ערוך'" class="pen" pack="fa" icon="pen"></b-icon>
                                </div>
                                <div style="cursor: pointer; margin-right: auto;" v-if="isEditModal === idx"
                                    @click="editResponse(response.id, response.text)">
                                    <b-icon :title="'ערוך'" class="save" pack="fa" icon="save"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ActivityComment__Component__Box__Content">
                        <!-- <div v-html="newMessage"></div> -->
                        <div class="ActivityComment__Component__Box__Content__Edit">
                            <NewActivityEditor :content="newMessage" ref="editor" :placeholder="'תגובה...'"
                                animation="''" />
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
// import LessonService from "../services/LessonService";
import CoursesService from "../services/CoursesService";
import NewActivityEditor from "./NewActivityEditor.vue";

export default {
    name: "DialogChat",
    props: ["reportNumbers", "reports", "onSuccess", "store", "id"],
    components: {
        NewActivityEditor
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
        requestId() {
            return this.id || this.store.state.request?.id;
        },
    },
    created() {
        this.getResponses();
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            responses: null,
            newMessage: '',
            isEditModal: null,
            model: {
                name: null,
                status: 1
            }
        };
    },
    methods: {
        getResponses() {
            this.isLoading = true;
            CoursesService.getRequestResponses(this.requestId, this.courseId)
                .then((r) => {
                    this.responses = r.data.content;
                    this.isLoading = false;
                });
        },
        deleteMessage(id) {
            this.isLoading = true;
            CoursesService.deleteResponse(this.requestId, this.courseId, id)
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "המחיקה בוצעה!",
                        duration: 4000,
                    });
                    this.getResponses();
                });
        },
        editResponse(id, text) {
            this.isLoading = true;
            CoursesService.editResponse(this.requestId, this.courseId, id, {
                Text: text
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "ההודעה עודכנה בהצלחה!",
                        duration: 4000,
                    });
                    this.getResponses();
                    this.isEditModal = null;
                });
        },
        openEditModal(idx) {
            if (this.isEditModal === idx) this.isEditModal = null;
            else this.isEditModal = idx;
        },
        save() {
            const body = this.$refs.editor.getHTML();
            this.newMessage = body;
            if (body) {
                this.isLoading = true;
                CoursesService.responseToRequest(this.requestId, this.courseId, {
                    Text: body
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        // this.$emit("close");
                        this.$refs.editor.clearEditor();
                        this.getResponses();
                        this.newMessage = null;
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.responses {
    display: flex;
    flex-direction: column;
    max-height: 600px;
    min-height: 200px;
    overflow: auto;
    margin-bottom: 10px;
}

.response {
    display: flex;
    flex-direction: column;
}

.user-response {
    position: relative;
    background-color: #9ddaf8;
    border-radius: 0.4em;
    padding: 10px;
    margin-top: 20px;
    width: 95%;
}

.admin-response {
    position: relative;
    background: #cecece;
    border-radius: 0.4em;
    padding: 10px;
    margin-top: 20px;
    width: 95%;
    align-self: end;
}

.response-seen {
    align-self: flex-end;
    gap: 5px;
    display: flex;
}

.response-buttons {
    display: flex;
    gap: 5px;
}

.NewTask__Component__Editor_Inner {
    background-color: #edfaff;
    padding: 0;
    border: 2px solid #2fc9e8;
    border-radius: 3px;
    margin-bottom: 10px;
    ;
}
</style>
